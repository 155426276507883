import React from "react";

const Footer = ({ handleClaimNowClick }) => (
  <div>
    <div className="w-full h-auto p-1 border-b bg-green-900 text-white grid place-items-center">
      <div className="flex gap-2 border-2 p-3 rounded-md bg-green-500">
        <img
          className="w-12 h-12 object-cover"
          src="https://clipground.com/images/clipart-whatsapp-logo-5.png"
          alt=""
        />
        <button onClick={handleClaimNowClick} className="text-3xl uppercase">
          Whatsapp now
        </button>
      </div>
    </div>
    <footer className="bg-black text-white md:px-32 py-4">
      <div className="container space-y-4 space-x-3 mx-auto text-center">
        <h1>
          Disclaimer:- This website is intended for users aged 18+ and complies
          with all local laws. Users from Telangana, Andra Pradesh, Orissa,
          Assam, Sikkim, and Nagaland should leave the website immediately. We
          provide cricket ID services through WhatsApp. Please note, our
          services do not involve real money transactions, and we comply with
          all local gambling laws.
        </h1>
        <p className="mt-4 text-yellow-400">"We Only Promote Fantasy Sports. No Real Money Involvement"</p>
        <p>Copyright © 2024.All Rights Reserved By <span className="text-yellow-400 underline">Phantom777</span></p>
      </div>
    </footer>
  </div>
);

export default Footer;
